import { ApolloClient, InMemoryCache, createHttpLink, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";

loadDevMessages();
loadErrorMessages();

let uri = 'http://localhost:8081/graphql'

if(!window.location.hostname.includes('localhost')){
    uri = 'https://datahub-registry-api.vetsuite.io/graphql'
}

const httpLink = createHttpLink({
    uri,
});
  
const authLink = setContext((_, { headers }) => {
// get the authentication token from local storage if it exists
    const sessionString = localStorage.getItem('session')
    let session : {token?: string} = {}
    try{
        if(sessionString){
            session = JSON.parse(sessionString)
        }
    }catch(error){
        console.log(error)
    }
    const token = session?.token;
    let optional = {}
    if(token){
        optional = {
            authorization: token ? `Bearer ${token}` : "",
        }
    }

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            ...optional
        }
    }
});
export namespace ApiClient {
    export type GetCalendar = GetAppointments & GetBlockoffs;
    
    export interface GetAppointments {
        appointments: {
            id: string,
            startsAt: string,
            endsAt: string,
            reason: string
            client?: {
                id: string,
                firstName: string,
                lastName: string,
                patient: {
                    id: string,
                    name: string
                }
            },
            resourceId: string,
            resource?:{
                id: string,
                firstName: string,
                lastName: string
            },
            appointmentType?:{
                id: string,
                name: string
            },
            appointmentStatus?:{
                id: string,
                name: string
            }
        }[]
    }
    export interface AddAgentVersion{
        addAgentVersionToSite: {
            id: number
            createdAt: string
            agentVersionId: number
        }
    }
    export interface RemoveAgentVersion{
        removeAgentVersionToSite: {
            success: boolean
        }
    }
    export interface GetAgentVersions{
        agentVersions: {
            id: number,
            createdAt: string,
            version: string,
            description: string
        }[]
    }
    export interface GetBlockoffs{
        blockoffs: {
            id: number,
            startsAt: string
            endsAt: string
            reason: string
            resourceId: string
            resource?: {
                id: string,
                fullName: string
            }
        }[]
    }
    export interface GetServers{
        servers: {
            id: number,
            url: string
            name: string
        }[]
    }
    export interface GetProvision{
        provisions: {
            ok: boolean,
        }[]
    }
    export interface GetCompanies {
        companies: {
            id: number,
            name: string
        }[]
    }
    export interface GetPractices {
        practices: {
            id: number,
            name: string
        }[]
    }
    export interface GetSites {
        sites: {
            id: number,
            createdAt: string,
            name: string,
            phoneNumber: string,
            description: string,
            integration: string,
            practiceId: number,
            companyId: number,
            isDataEnabled: boolean,
            currentAgentVersion: string,
            agentVersionsCount: string
        }[]
    }
    export interface GetSite {
        site: {
            id: number,
            createdAt: string,
            name: string,
            phoneNumber: string,
            description: string,
            integration: string,
            practiceId: number,
            isDataEnabled: boolean,
            currentAgentVersion: string,
            serverSiteId: string,
            serverSitePrivateKey: string,
            agentVersionUpgrades: {
                id: number,
                createdAt: string,
                version: string,
                description: string
                agentVersionId: number
                completedAt: string
            }[]
        }
    }
    export interface GetInitialData {
        companies: {
            id: number
            name: string
            practices?: {
                id: number
                name: string
                sites?: {
                    id: number
                    name: string
                }[]
            }[]
        }[]
    }
}
const apiClient = {
    connection: new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
    }),
    authenticate: (token: string)=>{
        if(token){
            return apiClient.connection.mutate({
                mutation: gql`
                    mutation($token: String!){
                        authenticate(token: $token){
                            firstName
                            lastName
                            email
                            mobilePhone
                            token
                            isSuper
                        }
                    }
                `,
                variables: {
                    token
                }
            })
        }
        return Promise.reject()
    },
    mutations: {
        CREATE_COMPANY: gql`
            mutation ($company: CompanyInput!){
                createCompany(company: $company){
                    id
                    name
                }
            }
        `,
        CREATE_PRACTICE: gql`
            mutation ($practice: PracticeInput!){
                createPractice(practice: $practice){
                    id
                    name
                }
            }
        `,
        CREATE_SITE: gql`
            mutation ($site: SiteInput!){
                createSite(site: $site){
                    id
                    name
                }
            }
        `,
        UPDATE_SITE: gql`
            mutation ($site: UpdateSiteInput!){
                updateSite(site: $site){
                    id
                    name
                    description
                    integration
                    phoneNumber
                }
            }
        `,
        ADD_AGENT_VERSION: gql`
            mutation ($agentVersionId: Int!, $siteId: Int!){
                addAgentVersionToSite(agentVersionId: $agentVersionId, siteId: $siteId){
                    id
                    createdAt
                    agentVersionId
                }
            }
        `,
        REMOVE_AGENT_VERSION: gql`
            mutation ($agentVersionId: Int!, $siteId: Int!){
                removeAgentVersionToSite(agentVersionId: $agentVersionId, siteId: $siteId){
                    success
                }
            }
        `,
        CREATE_AGENT_VERSION: gql`
            mutation ($agentVersion: AgentVersionInput!){
                createAgentVersion(agentVersion: $agentVersion){
                    id
                    createdAt
                    version
                    description
                }
            }
        `,
        PROVISION_SITE: gql`
            mutation($provision: ProvisionSiteInput){
                provisionSite(provision: $provision){
                    ok
                }
            }
        `
    },
    queries: {
        GET_COMPANIES: gql`
            query {
                companies {
                    id
                    name
                }
            }
        `,
        GET_PRACTICES: gql`
            query ($companyId: Int){
                practices(companyId: $companyId) {
                    id
                    name
                    companyId
                }
            }
        `,
        GET_SITES: gql`
            query {
                sites {
                    id
                    createdAt
                    description
                    name
                    integration
                    practiceId
                    companyId
                    phoneNumber
                    currentAgentVersion
                    isDataEnabled
                    agentVersionUpgrades {
                        version
                    }
                }
            }
        `,
        GET_SITE: gql`
            query ($id: Int){
                site (id: $id) {
                    id
                    createdAt
                    description
                    name
                    integration
                    practiceId
                    phoneNumber
                    currentAgentVersion
                    isDataEnabled
                    serverSiteId
                    serverSitePrivateKey
                    agentVersionUpgrades {
                        id
                        createdAt
                        version
                        description
                        agentVersionId
                        completedAt
                    }
                }
            }
        `,
        GET_APPOINTMENTS: gql`
            query ($siteId: String!, $startingAt: String!, $endingAt: String!){
                appointments(siteId: $siteId, startingAt: $startingAt, endingAt: $endingAt) {
                    id
                    startsAt
                    endsAt
                    reason
                    resourceId
                    client {
                        id
                        firstName
                        lastName
                        patients {
                            id
                            name
                        }
                    },
                    resource {
                        id
                        fullName
                    }
                    appointmentType{
                        id
                        name
                    }
                    appointmentStatus{
                        id
                        name
                    }
                }
            }
        `,
        GET_CALENDAR: gql`
            query ($siteId: String!, $startingAt: String!, $endingAt: String!, $resourceId: String){
                appointments(siteId: $siteId, startingAt: $startingAt, endingAt: $endingAt, resourceId: $resourceId) {
                    id
                    startsAt
                    endsAt
                    reason
                    resourceId
                    client {
                        id
                        firstName
                        lastName
                        patients {
                            id
                            name
                        }
                    }
                    appointmentStatus{
                        id
                        name
                    }
                    appointmentType{
                        id
                        name
                    }
                },
                blockoffs(siteId: $siteId, startingAt: $startingAt, endingAt: $endingAt, resourceId: $resourceId) {
                    id
                    startsAt
                    endsAt
                    resourceId
                    resource {
                        id
                        fullName
                    }
                }
            }
        `,
        GET_CURRENT_USER_DATA: gql`
            query {
                companies {
                    id
                    name
                    practices{
                        id
                        name
                        sites{
                            id
                            name
                        }
                    }
                }
            }
        `,
        GET_AGENT_VERSIONS: gql`
            query {
                agentVersions{
                    id
                    createdAt
                    version
                    description
                }
            }
        `,
        GET_SERVERS: gql`
            query {
                servers{
                    id
                    createdAt
                    name
                    url
                }
            }
        `
    }
}

export default apiClient