import { useMutation, useQuery } from "@apollo/client";
import apiClient, { ApiClient } from "../../lib/apiClient";
import useSession from "../../helpers/useSessionState";
import {
  Button,
  Drawer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { Link, Routes, Route, useParams, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from '@mui/icons-material/Remove';

import Layout from "../components/elements/Layout";
import { DateTime } from "luxon";
import DataDrawer from "../components/elements/DataDrawer";
import AgentVersionUpload from "../components/elements/AgentVersionUpgrade";
import SiteUpdate from "../components/partials/Sites/SiteUpdate";
import SiteUpdateDrawer from "../components/partials/Sites/SiteUpdateDrawer";
import CloseIcon from '@mui/icons-material/Close';
import Provision from "../components/partials/Provisioning";

type State = {
  drawerOpen: boolean;
  site?: ApiClient.GetSites["sites"][0];
  showPrivateKey: boolean;
};

const Site = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [state, setState] = useState<State>({
    drawerOpen: false,
    showPrivateKey: false
  });

  const {
    loading,
    error,
    data,
    refetch: siteRefetch,
  } = useQuery<ApiClient.GetSite>(apiClient.queries.GET_SITE, {
    variables: {
      id: parseInt(id ?? ""),
    },
  });

  const [
    removeAgentVersionUpgrade,
    { loading: createAgentVersionLoading, error: createAgentVersionError },
  ] = useMutation<ApiClient.RemoveAgentVersion["removeAgentVersionToSite"]>(
    apiClient.mutations.REMOVE_AGENT_VERSION
  );

  const [sessionState, setSession] = useSession();
  const setDrawer = (value: boolean) => {
    setState({
      ...state,
      drawerOpen: value,
    });
  };

  const agentVersionComplete = async () => {
    siteRefetch();
    setState({
      drawerOpen: false,
      showPrivateKey: false
    });
  };

  const removeAgentVersion = async (agentVersionId: number) => {
    await removeAgentVersionUpgrade({
      variables: {
        siteId: parseInt(id as string),
        agentVersionId,
      },
    });

    siteRefetch();
  };

  const handleShowPrivateKey = () => {
    setState({
      showPrivateKey: !state.showPrivateKey,
      drawerOpen: state.drawerOpen
    })
  }


  return (
    <Layout page="Sites">
      {loading ? (
        <>LOADING...</>
      ) : error ? (
        <>{error.message}</>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "left",
            }}
          >
            <div>
              <div>Id: {data?.site?.id}</div>
              <div>Created At: {data?.site?.createdAt}</div>
              <div>Name: {data?.site?.name}</div>
              <div>Description: {data?.site?.description}</div>
              <div>Integration: {data?.site?.integration}</div>
              <div>Phone Number: {data?.site?.phoneNumber}</div>
              Agent Updates: <br />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
              }}
            >
              {data?.site &&(
                <SiteUpdateDrawer onUpdate={siteRefetch} site={data?.site} />
              )}

              {data?.site?.serverSitePrivateKey ? (
                <div>
                  Site is provisioned
                  <Button onClick={handleShowPrivateKey}>
                    {state.showPrivateKey ? 'Hide' : 'Show'}
                  </Button>
                  {state.showPrivateKey && data?.site?.serverSitePrivateKey && data?.site?.serverSitePrivateKey}
                </div>
              ):(
                <div style={{textAlign: "right"}}>
                  Site is not currently provisioned
                  <br />
                  <Provision site={{id: data?.site?.id}} />
                </div>
              )}

            </div>
          </div>
          <br />
          <div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell align="left">Created At</TableCell>
                    <TableCell align="right">Version</TableCell>
                    <TableCell align="left">Description</TableCell>
                    <TableCell align="left">Completed At</TableCell>
                    <TableCell align="right">
                      <AgentVersionUpload
                        siteId={parseInt(id as string)}
                        onCompleted={agentVersionComplete}
                      />
                  </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.site?.agentVersionUpgrades?.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell align="right">{row.createdAt}</TableCell>
                      <TableCell align="right">{row.version}</TableCell>
                      <TableCell align="left">{row.description}</TableCell>
                      <TableCell align="right">{row.completedAt}</TableCell>
                      <TableCell align="right">
                        <Button onClick={removeAgentVersion.bind(null, row.id)}>
                          <CloseIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}
    </Layout>
  );
};

export default Site;
