import { useState } from "react"
import ResponsiveDrawer from "../../elements/Drawer"
import { Button } from "@mui/material"
import { useQuery } from "@apollo/client"
import apiClient, { ApiClient } from "../../../../lib/apiClient"
import ProvisioningForm from "./ProvisioningForm"

interface Props {
    site: {
        id: number | undefined
    },
    onCancel?: Function
}
const Provision = (props: Props)=>{
    const [state, setState] = useState({
        drawerOpen: false
    })
    const setDrawer = (value: boolean)=>{
        setState({
            ...state,
            drawerOpen: value
        })
    }

    const { loading, error, data, refetch} = useQuery<ApiClient.GetServers>(apiClient.queries.GET_SERVERS);


    const handleCancel = ()=>{
        setState({
            drawerOpen: false
        })
        props?.onCancel?.()
    }

    return <>
        <Button onClick={setState.bind(null, {drawerOpen: true})} variant="contained">
            Provision
        </Button>
        <ResponsiveDrawer
            anchor='right'
            open={state.drawerOpen}
            onClose={setDrawer.bind(null, false)}
        >
            <ProvisioningForm site={props.site} onUpdate={handleCancel}/>
        </ResponsiveDrawer>
    </>
}

export default Provision