export const fileReader = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
        if (reader.result) {
            const base64String = (reader.result as string)
            .replace('data:', '')
            .replace(/^.+,/, '');
    
            resolve(base64String);
        }
        }
        reader.readAsDataURL(file);
    });
}