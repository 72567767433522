import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import apiClient, { ApiClient } from "../../../../lib/apiClient";
import { useMutation, useQuery } from "@apollo/client";
import useSession from "../../../../helpers/useSessionState";
import { useForm } from "react-hook-form";

interface SiteUpdateProps {
  site: {
    id: number;
    name: string;
    description: string;
    integration: string;
    phoneNumber: string;
    practiceId: number
    isDataEnabled: boolean
  };
  onUpdate: () => void;
}
interface SiteFormInputs {
  id: number,
  name: string,
  description: string,
  integration: string,
  phoneNumber: string,
  practiceId: number
  isDataEnabled: boolean
}
const SiteUpdate = (props: SiteUpdateProps) => {
  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm<SiteFormInputs>()

  const { site, onUpdate } = props;

  const [sessionState, setSession] = useSession()

  const [updateSite] = useMutation<ApiClient.GetSites["sites"][0]>(
    apiClient.mutations.UPDATE_SITE
  );

  const {data: practicesData} = useQuery<ApiClient.GetPractices>(
    apiClient.queries.GET_PRACTICES,
    {
      variables: {
        companyId: sessionState?.currentCompanyId
      }
    }
  );

  const [data, setData] = useState(site);

  const valueSetter = (value: string, key: string) => {
    setData((prev) => ({ ...prev, [key]: value }));
  };

  const handleUpdateSite = (params: SiteFormInputs) => {
    updateSite({
      variables: {
        site: {
          ...params,
          practiceId: parseInt(params.practiceId as unknown as string),
          id: parseInt(site.id as unknown as string),
        },
      },
    })
      .then(() => {
        onUpdate();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div style={{ width: "400px", padding: "25px 20px" }}>
      <Typography variant="h5" sx={{ marginBottom: "20px" }}>
        Update Site
      </Typography>
      <form onSubmit={handleSubmit(handleUpdateSite)}>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel htmlFor="Practice">Practice</InputLabel>


            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Practices</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={data?.practiceId}
                label="Age"
                {...register("practiceId")}
              >
                {practicesData?.practices?.map((practice)=>
                  <MenuItem value={practice.id}>{practice.name}</MenuItem>
                )}
              </Select>
            </FormControl>
          </FormControl>

          <FormControl style={{ width: "100%" }}>
            <InputLabel htmlFor="Name">Name</InputLabel>
            <Input
              fullWidth
              defaultValue={data?.name}
              {...register("name")}
            />
          </FormControl>

          <FormControl style={{ width: "100%" }}>
            <InputLabel htmlFor="Description">Description</InputLabel>
            <Input
              value={data?.description}
              fullWidth
              defaultValue={data?.description}
              {...register("description")}
            />
          </FormControl>

          <FormControl style={{ width: "100%" }}>
            <InputLabel htmlFor="Name">Integration</InputLabel>
            <Input
              defaultValue={data?.integration}
              fullWidth
              {...register("integration")}
            />
          </FormControl>

          <FormControl style={{ width: "100%" }}>
            <InputLabel htmlFor="Name">Phone Number</InputLabel>
            <Input
              defaultValue={data?.phoneNumber}
              fullWidth
              {...register("phoneNumber")}
            />
          </FormControl>
          <FormControlLabel
            style={{ width: "100%" }}
            control={
              <Switch
                defaultChecked={data?.isDataEnabled}
                {...register("isDataEnabled")}
              />
            } 
            label="Site Enabled"
          />
        </div>

        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button
            variant="contained"
            style={{ marginTop: "20px" }}
            type="submit"
          >
            Save
          </Button>&nbsp;
          <Button
            variant="contained"
            style={{ marginTop: "20px" }}
            type="submit"
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SiteUpdate;
