import { useQuery } from "@apollo/client";
import apiClient, { ApiClient } from "../../../../lib/apiClient";
import useSession from "../../../../helpers/useSessionState";
import { Button, Drawer, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useState } from "react";
import CreateCompany from "./CompanyCreate";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../elements/Layout";
import AddIcon from '@mui/icons-material/Add';
import ResponsiveDrawer from "../../elements/Drawer";

const CompaniesPartial = ()=>{
    const [state, setState] = useState({
        drawerOpen: false
    })

    const navigate = useNavigate()
    const { loading, error, data, refetch } = useQuery<ApiClient.GetCompanies>(apiClient.queries.GET_COMPANIES);

    const [sessionState, setSession] = useSession()
    const setDrawer = (value: boolean)=>{
        setState({
            ...state,
            drawerOpen: value
        })
    }
    
    const onComplete = ()=>{
        refetch()
        setDrawer(false)
    }
    
    
    const handleSelectCompany = (company: ApiClient.GetCompanies["companies"][0], e: any)=>{
        setSession({
            ...Object(sessionState),
            currentCompanyId: company.id
        })
        navigate("/")
    }

    return (
        <>
            {loading ? (
                <>
                    LOADING...
                </>
            ):error?(
                error.message
            ):(
                <>
                    {sessionState?.isSuper && (
                        <div>
                            <ResponsiveDrawer
                                anchor='right'
                                open={state.drawerOpen}
                                onClose={setDrawer.bind(null, false)}
                            >
                                <CreateCompany onComplete={onComplete} onCancel={setDrawer.bind(null, false)}/>
                            </ResponsiveDrawer>
                        </div>
                    )}
                    
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">
                                    {sessionState?.isSuper && (
                                        <Button variant="contained" onClick={setDrawer.bind(null, true)} size='small'>
                                            <AddIcon />
                                        </Button>
                                    )}
                                </TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.companies.map((row) => (
                                     <TableRow
                                     key={row.name}
                                     sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                   >
                                     <TableCell component="th" scope="row">
                                       {row.id}
                                     </TableCell>
                                     <TableCell>{row.name}</TableCell>
                                     <TableCell><Button onClick={handleSelectCompany.bind(null, row)}>Use</Button></TableCell>
                                   </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </>
    )
}

                        // {data?.companies.map((company, i: Number)=>(
                        //     <div key={i.toString()}>
                        //         <Link to="#" onClick={handleSelectCompany.bind(null, company)}>
                        //             {company.name}
                        //         </Link>
                        //     </div>
                        // ))}
export default CompaniesPartial