import { useMutation } from "@apollo/client";
import { Box, Button, FormControl, Input, InputLabel, Typography } from "@mui/material";
import { useForm } from "react-hook-form"
import apiClient, { ApiClient } from "../../../../lib/apiClient";

interface CompanyFormInputs {
    name: string
}

interface CompanyCreateProps {
    onComplete?: Function,
    onCancel?: Function
}
const CreateCompany = (props: CompanyCreateProps)=>{
    const {
        register,
        handleSubmit,
        // formState: { errors },
    } = useForm<CompanyFormInputs>()
    const [createCompany, { loading, error }] = useMutation<ApiClient.GetCompanies["companies"][0]>(apiClient.mutations.CREATE_COMPANY);

    const handleCreateCompany = (company: any)=>{
        createCompany({
            variables: {company}
        }).then(()=>{
            if(props.onComplete) props.onComplete()
        }).catch((error)=>{
            console.log(error)
        })
    }

    const handleCancel : React.MouseEventHandler<HTMLButtonElement> = (e)=>{
        e.preventDefault()

        props?.onCancel?.()
    }

    return (
        <Box
            sx={{p: 2}}
        >
            <form onSubmit={handleSubmit(handleCreateCompany)}>
                {/* register your input into the hook by invoking the "register" function */}
                {loading ? (<>
                    Loading...
                </>):(
                    <>
                        {error && error?.message}
                        <FormControl>
                            <Typography component="h4">
                                New Company
                            </Typography>
                        </FormControl>
                        <br />
                        <br />
                        <FormControl>
                            <InputLabel htmlFor="name">Company Name</InputLabel>
                            <Input id="name" defaultValue="" {...register("name")} required={true} />
                        </FormControl>

                        {/* include validation with required or other standard HTML validation rules */}
                        {/* errors will return when field validation fails  */}
                        <br />
                        <Button type="submit">Create</Button>&nbsp;
                        {props?.onCancel && <Button onClick={handleCancel}>Cancel</Button>}
                    </>
                )}
            </form>
        </Box>
    )
}

export default CreateCompany