import { Link } from "react-router-dom"
import useSession from "../../helpers/useSessionState"
import Layout from "../components/elements/Layout"
import { Calendar as RBCCalendar, Views, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { useMutation, useQuery } from "@apollo/client"
import apiClient, { ApiClient } from "../../lib/apiClient"
import { useState } from "react"
import randomColor from 'randomcolor';
import color from 'color';
import { Box, Button, Drawer, MenuItem, Paper, Select, SelectChangeEvent } from "@mui/material"
import UploadAgent from "../components/elements/UploadAgent"
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Table from '@mui/material/Table';
import {TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {DateTime} from 'luxon'

const AgentVersions = ()=>{
    const [sessionState] = useSession()

    const { loading: resourceLoading, error: resourceError, data: resourceData, refetch: agentVersionRefetch } = useQuery<ApiClient.GetAgentVersions>(apiClient.queries.GET_AGENT_VERSIONS, {
        pollInterval: 0
    });
    
    const navigateToBase64 = (version: {id: number, version: string, description: string}) => {
        var a = document.createElement("a"); //Create <a>
        // a.href = "data:image/png;base64," + version.file; //Image Base64 Goes here
        a.download = `${version.version}.dhu`; //File name Here
        a.click(); //Downloaded file
    }

    const onCompleted = ()=>{
        agentVersionRefetch()
    }

    return (
        <Layout page="Agent Versions">
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Created At</TableCell>
                        <TableCell>Version</TableCell>
                        <TableCell align="right">Description</TableCell>
                        <TableCell align="right">
                            {sessionState?.isSuper && (
                                <UploadAgent onCompleted={onCompleted} />
                            )}
                        </TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {resourceData?.agentVersions?.map((row, i: Number)=>(
                            <TableRow
                                key={row.createdAt}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell>
                                    {DateTime.fromISO(row.createdAt).toFormat('LL/dd/y hh:mm a') }
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.version}
                                </TableCell>
                                <TableCell align="right">{row.description}</TableCell>
                                
                                <TableCell>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Layout>
    )
}
export default AgentVersions