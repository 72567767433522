import { useQuery } from "@apollo/client";
import apiClient, { ApiClient } from "../../lib/apiClient";
import useSession from "../../helpers/useSessionState";
import { Button, Drawer } from "@mui/material";
import { useState } from "react";
import CreateCompany from "../components/partials/Companies/CompanyCreate";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../components/elements/Layout";
import CompaniesPartial from "../components/partials/Companies";

const Companies = ()=>{
    return <Layout page="Companies">
        <CompaniesPartial />
    </Layout>
}

export default Companies