import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { useForm } from 'react-hook-form';
import { Input, OutlinedInput, TextField } from '@mui/material';
import { fileReader } from '../../../../lib/fileReader';
import { create } from 'domain';
import { useMutation } from '@apollo/client';
import apiClient, { ApiClient } from '../../../../lib/apiClient';
import AddIcon from '@mui/icons-material/Add';
import ResponsiveDrawer from '../Drawer';

type Inputs = {
  description: string
  version: string
  file: any
}

type Props = {
  onCompleted: () => void
  onCancel?: Function
}

const UploadAgent = (props: Props) =>{
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    console.log(newOpen)
    setOpen(newOpen);
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>()

  const [createAgentVersion, { 
    loading: createAgentVersionLoading, 
    error: createAgentVersionError 
  }] = useMutation<ApiClient.GetAgentVersions["agentVersions"][0]>(
    apiClient.mutations.CREATE_AGENT_VERSION
  );

  const submitForm = async(formData: any, e: any) => {
    // saveData(formData);
    e.preventDefault();

    const data = await fileReader(formData.file[0]);
    
    await createAgentVersion({
      variables: {
        agentVersion: {
          description: formData.description,
          version: formData.version,
          file: data
        }
      }
    })

    setOpen(false)
    props.onCompleted();
  };

  const handleCancel : React.MouseEventHandler<HTMLButtonElement> = (e)=>{
    e.preventDefault()

    setOpen(false)

    props?.onCancel?.()
  }

  return (
    <>

      <Button onClick={toggleDrawer(true)} variant='outlined'>
        <AddIcon />
      </Button>
      <ResponsiveDrawer open={open} onClose={toggleDrawer(false)} anchor='right'>
        <Box sx={{ width: '100%' }} role="presentation" p={4}>
          <form onSubmit={handleSubmit(submitForm)}>
            <br />
            <TextField 
              defaultValue="" 
              label="Version" 
              {...register("version")}
              required={true} 
              variant="outlined"
              style={{width: '100%'}}
            />
            <br />
            <br />
            <TextField 
              defaultValue="" 
              label="Description" 
              {...register("description")}
              required={true} 
              variant="outlined"
              style={{width: '100%'}}
            />
            <br />
            <br />
            <Input
              {...register("file", {
                required: "Recipe picture is required",
              })}
              type="file"
            />
            <br />
            <br />
            <Button type="submit" variant='contained'>Save</Button>
            &nbsp;
            {/* {props?.onCancel && ( */}
              <Button variant='contained' onClick={handleCancel}>Cancel</Button>
            {/* )} */}
          </form>
        </Box>
      </ResponsiveDrawer>
    </>
  );
}

export default UploadAgent