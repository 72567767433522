import { Link, useNavigate } from "react-router-dom"
import useSession from "../../../../helpers/useSessionState"
import { Divider, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import DashboardIcon from '@mui/icons-material/Dashboard';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LogoutIcon from '@mui/icons-material/Logout';
import useBreakpoints from "../../../../helpers/useMedia";
import SettingsIcon from '@mui/icons-material/Settings';

interface Props{
  onNavigate?: Function
  onClose?: Function
}
const MenuBar = (props: Props)=>{
    const navigate = useNavigate()
    const {isLg} = useBreakpoints()
    const [sessionState, setSession] = useSession()
    // <Link to='/practices'>Practices</Link>
    // <Link to='/sites'>Sites</Link>
    // <Link to='/calendar'>Calendar</Link>
    const navigateTo = (target: string)=>{
      navigate(target)
      props?.onNavigate?.()
    }

    const handleClose = ()=>{
      props?.onClose?.()
    }

    const handleLogout = () => {
      setSession(undefined)
    };

    return (
        <>
        <ListItemButton onClick={navigateTo.bind(null, '/')}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
        <Divider />
          {sessionState?.isSuper && (
            <>
              <ListItemButton onClick={navigateTo.bind(null, '/agent-versions')}>
                  <ListItemIcon>
                      <CloudUploadIcon />
                  </ListItemIcon>
                  <ListItemText primary="Agent Versions" />
              </ListItemButton>
              <Divider />
              <ListItemButton onClick={navigateTo.bind(null, '/companies')}>
                <ListItemIcon>
                  <ApartmentIcon />
                </ListItemIcon>
                <ListItemText primary="Companies" />
              </ListItemButton>
            </>
          )}
        <ListItemButton onClick={navigateTo.bind(null, '/practices')}>
          <ListItemIcon>
            <WorkspacesIcon />
          </ListItemIcon>
          <ListItemText primary="Practices" />
        </ListItemButton>
        <ListItemButton onClick={navigateTo.bind(null, '/sites')}>
          <ListItemIcon>
            <StoreOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Sites" />
        </ListItemButton>
        {!isLg && <>
          <Divider />
          <ListItemButton onClick={navigateTo.bind(null, '/practices')}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="settings" />
          </ListItemButton>
          <Divider />
          <ListItemButton onClick={handleClose} style={{textAlign: 'center'}}>
            <ListItemText primary="Cancel" />
          </ListItemButton>
          <Divider />
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </>}
      </>
    )
}

export default MenuBar