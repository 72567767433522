import { useQuery } from "@apollo/client";
import useSession from "../../../../helpers/useSessionState"
import apiClient, { ApiClient } from "../../../../lib/apiClient";
import * as React from 'react';
import { styled, createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import MenuBar from "./MenuBar";
import logo from "./logo.png"
import DropdownMenu from "./DropdownMenu";
import { useNavigate } from "react-router-dom";
import CompanyDropdown from "../CompanyDropdown";
import PracticeDropdown from "../PracticeDropdown";
import useBreakpoints from "../../../../helpers/useMedia";
import MobileMenu from "./MobileMenu";

// import { mainListItems, secondaryListItems } from './listItems';
// import Chart from './Chart';
// import Deposits from './Deposits';
// import Orders from './Orders';


function Copyright(props: any) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://mui.com/">
          Next In Line
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
  

  const drawerWidth: number = 240;

  interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
  }
  
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));
  
  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: theme.spacing(7),
          [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
          },
        }),
      },
    }),
  );
  
  // TODO remove, this demo shouldn't need to reset the theme.
  const defaultTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  
const Layout = ({children, page}:React.PropsWithChildren & {page: string})=>{
    const [sessionState] = useSession()
    const navigate = useNavigate()
    const theme = useTheme()
    
    const {isSm, isMd, isLg} = useBreakpoints() 
    const { data } = useQuery<ApiClient.GetInitialData>(apiClient.queries.GET_CURRENT_USER_DATA);
    
    const company = data?.companies.find((company)=>
        company.id === sessionState?.currentCompanyId
    )

    const practice = company?.practices?.find((practice)=>
        practice.id === sessionState?.currentPracticeId
    )
    const site = practice?.sites?.find((site)=>
        site.id === sessionState?.currentSiteId
    )
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = () => {
      setOpen(!open);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
          <Box sx={{ display: 'flex' }}>
              <CssBaseline />
              <AppBar position="absolute" open={!isSm && !isMd} sx={{zIndex: 1200}}>
                <Toolbar
                    sx={{
                    }}
                >
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="open drawer"
                      onClick={toggleDrawer}
                      sx={{
                          marginRight: '36px',
                          ...(isLg && { display: 'none' }),
                      }}
                    >
                      <MenuIcon />
                    </IconButton>
                    <Typography
                      component="h1"
                      variant="h6"
                      color="inherit"
                      noWrap
                      sx={{ flexGrow: 1 }}
                    >
                      <div>
                      
                      </div>
                      <CompanyDropdown />
                      - 
                      <PracticeDropdown />
                    </Typography>
                    {isLg && <DropdownMenu />}
                    {/* <IconButton color="inherit" >
                    <Badge badgeContent={4} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                    </IconButton> */}
                </Toolbar>
              </AppBar>
              

              <Drawer variant={isLg ? "permanent" : "temporary"} open={true}>
                <div>
                    <img src={logo} style={{width: 45, height: 45, display: "inline-block", verticalAlign: "middle"}} /> 
                    <div style={{
                      display: 'inline-block',
                      verticalAlign: "middle",
                      fontSize: "2em",
                      fontWeight: "lighter",
                      color: "#015fac"
                    }}>
                      Datahub
                    </div>
                </div>
                <Divider />
                <List component="nav">
                    <MenuBar />
                </List>
              </Drawer>
              <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
              >
              <Toolbar />
              <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                  <Grid container spacing={3}>
                  {/* Chart */}
                      <Grid item xs={12} md={12} lg={12}>
                          <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                            style={{position: 'relative'}}
                            elevation={0}
                          >
                              {children}

                          </Paper>
                      </Grid>  
                  </Grid>
                  <Copyright sx={{ pt: 4 }} />
              </Container>
              </Box>
          </Box>
          {!isLg && <MobileMenu open={open} onClose={setOpen.bind(null, false)}/>}
        </ThemeProvider>
    )
}

export default Layout