import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { useForm } from 'react-hook-form';
import { FormControl, Input, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { fileReader } from '../../../../lib/fileReader';
import { create } from 'domain';
import { useMutation, useQuery } from '@apollo/client';
import apiClient, { ApiClient } from '../../../../lib/apiClient';
import AddIcon from '@mui/icons-material/Add';
import ResponsiveDrawer from '../Drawer';
import _ from 'lodash';

type Inputs = {
  agentVersionId: number
}

type Props = {
  siteId: number,
  onCompleted: () => void
}

const AgentVersionUpload = (props: Props) =>{
  const [open, setOpen] = React.useState(false);

  const { loading: agentVersionLoading, error: agentVersionError, data: agentVersionData } = useQuery<ApiClient.GetAgentVersions>(apiClient.queries.GET_AGENT_VERSIONS, {
    pollInterval: 0
  });

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>()

  const [addAgentVersionUpgrade, { 
    loading: createAgentVersionLoading, 
    error: createAgentVersionError 
  }] = useMutation<ApiClient.AddAgentVersion["addAgentVersionToSite"]>(
    apiClient.mutations.ADD_AGENT_VERSION
  );

  const submitForm = async(formData: any, e: any) => {
    // saveData(formData);
    e.preventDefault();

    
    await addAgentVersionUpgrade({
      variables: {
        agentVersionId: parseInt(formData.agentVersionId),
        siteId: props.siteId,
      }
    })

    props.onCompleted()
    setOpen(false)
  };

  const agentVersions = agentVersionData?.agentVersions || []

  return (
    <>
      <Button onClick={toggleDrawer(true)} variant='outlined' style={{float: 'right'}}>
        <AddIcon />
      </Button>
      <ResponsiveDrawer open={open} onClose={toggleDrawer(false)} anchor='right'>
        <Box sx={{ width: "100%" }} role="presentation" p={4}>
          <form onSubmit={handleSubmit(submitForm)}>
            <br />

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Version</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Version"
                {...register('agentVersionId')}
                // onChange={handleChange}
              >
                {agentVersions.map((agentVersion)=>(
                  <MenuItem key={agentVersion.id} value={agentVersion.id}>{agentVersion.version}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <Button type="submit" variant='contained'>Save</Button>
          </form>
        </Box>
      </ResponsiveDrawer>
    </>
  );
}

export default AgentVersionUpload