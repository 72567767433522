import React, { useState } from "react";
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import apiClient, { ApiClient } from "../../../../lib/apiClient";
import { useMutation, useQuery } from "@apollo/client";
import useSession from "../../../../helpers/useSessionState";
import { useForm } from "react-hook-form";

interface ProvisionUpdateProps {
  site: {
    id: number | undefined;
  };
  onUpdate: () => void;
}
interface ProvisionFormInputs {
  siteId: number,
  serverId: number
}
const ProvisioningForm = (props: ProvisionUpdateProps) => {
  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm<ProvisionFormInputs>()

  const { site, onUpdate } = props;

  const [sessionState, setSession] = useSession()

  const [updateProvision] = useMutation<ApiClient.GetServers["servers"][0]>(
    apiClient.mutations.PROVISION_SITE
  );

  const { loading, error, data, refetch} = useQuery<ApiClient.GetServers>(apiClient.queries.GET_SERVERS);

  const handleUpdateProvision = (params: ProvisionFormInputs) => {
    updateProvision({
      variables: {
        provision: {
          serverId: parseInt(params.serverId as unknown as string),
          siteId: parseInt(site.id as unknown as string),
        },
      },
    })
      .then(() => {
        onUpdate();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div style={{ width: "400px", padding: "25px 20px" }}>
      <Typography variant="h5" sx={{ marginBottom: "20px" }}>
        Update Provision
      </Typography>
      <form onSubmit={handleSubmit(handleUpdateProvision)}>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel htmlFor="Practice">Practice</InputLabel>


            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Servers</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Age"
                {...register("serverId")}
              >
                {data?.servers?.map((server)=>
                  <MenuItem value={server.id}>{server.name}</MenuItem>
                )}
              </Select>
            </FormControl>
          </FormControl>
        </div>

        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button
            variant="contained"
            style={{ marginTop: "20px" }}
            type="submit"
          >
            Save
          </Button>&nbsp;
          <Button
            variant="contained"
            style={{ marginTop: "20px" }}
            type="submit"
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ProvisioningForm;
