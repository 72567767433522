import { Button} from "@mui/material";

let publicKey = "2f70283a1154479ef3ffba5329fc3b34425b8c4e99f87437cd"
if(window.location.hostname.includes("localhost")){
    publicKey = "ce71f4f5eee04c273a8e180549e27162301891d580267c654e"
}

const Authenticate = ()=>{
    return (
        <div style={{textAlign: "center"}}>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Button 
                onClick={()=>window.location.href=`https://authentication.vetsuite.io?publicKey=${publicKey}`} 
                variant="contained"
            >
                Authenticate
            </Button>
        </div>
    )
}
export default Authenticate;