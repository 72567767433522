import { useQuery } from "@apollo/client";
import { Button, Menu, MenuItem } from "@mui/material";
import apiClient, { ApiClient } from "../../../../lib/apiClient";
import React from "react";
import useSession from "../../../../helpers/useSessionState";
import { useNavigate } from "react-router-dom";

const PracticeDropdown = ()=>{
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate()
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const [sessionState, setSession] = useSession()
    const { data } = useQuery<ApiClient.GetInitialData>(apiClient.queries.GET_CURRENT_USER_DATA);
    
    const handleSelect = (practice: ApiClient.GetPractices["practices"][0], e: any)=>{
        setSession({
            ...Object(sessionState),
            currentPracticeId: practice.id
        })
        handleClose()
    }
        
    const company = data?.companies?.find((company)=>
        company.id == sessionState?.currentCompanyId
    )
    
    const practice = company?.practices?.find((practice)=>
        practice.id === sessionState?.currentPracticeId
    )


    return <>
        <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
        >
            {practice?.name}
        </Button>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            {company?.practices?.map((_practice)=>(
                <MenuItem 
                    onClick={handleSelect.bind(null, _practice)}
                    key={_practice.id}
                >
                    {_practice.name}
                </MenuItem>
            ))}
        </Menu>
    </>
}

export default PracticeDropdown