import { Divider, Drawer, List, styled } from "@mui/material"
import logo from "./logo.png"
import MenuBar from "./MenuBar"


const FullWidthDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
      width: '100%',
      backgroundImage: "none"
    },
}));

interface Props {
    open: boolean | undefined,
    onClose: Function,
    onNavigate?: Function 
}

const MobileMenu = (props: Props)=>{
    const handleNavigation = ()=>{
        props?.onNavigate?.()
        props?.onClose?.()
    }
    
    return (
        <FullWidthDrawer open={props.open} anchor='left'>
            <div style={{width: '100%'}}>
                <img src={logo} style={{width: 45, height: 45, display: "inline-block", verticalAlign: "middle"}} /> 
                <div style={{
                    display: 'inline-block',
                    verticalAlign: "middle",
                    fontSize: "2em",
                    fontWeight: "lighter",
                    color: "#015fac"
                }}>
                    Datahub
                </div>
            </div>
            <Divider />
            <List component="nav">
                <MenuBar onNavigate={handleNavigation} onClose={props.onClose}/>
            </List>
        </FullWidthDrawer>
    )
}

export default MobileMenu