import {
    createBrowserRouter,
} from "react-router-dom";
import Companies from "./pages/Companies";
import Practices from "./pages/Practices";
import Sites from "./pages/Sites";
import Site from "./pages/Site";
import Home from "./pages/Home";
import AgentVersions from "./pages/AgentVersions";

const router = createBrowserRouter([
    {
      path: "/companies",
      Component: Companies,
    },
    {
      path: "/practices",
      Component: Practices,
    },
    {
      path: "/sites",
      Component: Sites,
    },
    {
      path: "/sites/:id",
      Component: Site,
    },
    {
      path: "/agent-versions",
      Component: AgentVersions,
    },
    {
      path: "/about",
      element: <div>About</div>,
    },
    {
        path: "*",
        Component: Home
    }
]);


export default router