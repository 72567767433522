import { Button, Drawer } from "@mui/material"
import { useState } from "react"
import SiteUpdate from "./SiteUpdate"
import EditIcon from '@mui/icons-material/Edit';
import ResponsiveDrawer from "../../elements/Drawer";

interface SiteUpdateProps {
    site: {
      id: number;
      name: string;
      description: string;
      integration: string;
      phoneNumber: string;
      practiceId: number
      isDataEnabled: boolean
    };
    onUpdate: () => void;
}

const SiteUpdateDrawer = (props: SiteUpdateProps)=>{
    const [state, setState] = useState({
        drawerOpen: false
    })
    const setDrawer = (value: boolean)=>{
        setState({
            ...state,
            drawerOpen: value
        })
    }
    
    const onUpdate = ()=>{
        setDrawer(false)
        props.onUpdate()
    }

    return <>
        <div style={{textAlign: 'right'}}>
            <Button onClick={setDrawer.bind(null, true)} style={{display: 'inline-block'}}>
                <EditIcon />
            </Button>
        </div>
        <ResponsiveDrawer
            anchor='right'
            open={state.drawerOpen}
            onClose={setDrawer.bind(null, false)}
        >
            <SiteUpdate site={props.site} onUpdate={onUpdate} />
        </ResponsiveDrawer>
    </>
}

export default SiteUpdateDrawer