import { useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';

const useBreakpoints = ()=>{
    const theme = useTheme()

    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const isLg = useMediaQuery(theme.breakpoints.up('md'));
    
    const isAbove = useMediaQuery(theme.breakpoints.up('sm')) 
    const isBelow = useMediaQuery(theme.breakpoints.down('md'))  
    const isMd = isAbove && isBelow
    
    return {
        isSm,
        isMd,
        isLg
    }
}

export default useBreakpoints
