import { Link } from "react-router-dom"
import useSession from "../../helpers/useSessionState"
import Layout from "../components/elements/Layout"
import Companies from "./Companies"
import CompaniesPartial from "../components/partials/Companies"
import PracticesPartial from "../components/partials/Practices"

const Home = ()=>{
    const [sessionState] = useSession()
    return (
        <Layout page="Home">
            <h3 style={{textAlign: 'left'}}>
                Companies:
            </h3>
            <CompaniesPartial />
            <br />
            <br />
            <h3 style={{textAlign: 'left'}}>
                Practices:
            </h3>
            {sessionState?.isSuper && sessionState?.currentCompanyId != null ? (
                <PracticesPartial />
            ):(
                <>
                    <h4>
                        Please Select a Company
                    </h4>
                </>
            )}
        </Layout>
    )
}
export default Home