import styled from "styled-components";
import useBreakpoints from "../../../../helpers/useMedia";
import { Drawer } from "@mui/material";
import {PropsWithChildren} from "react"

const FullWidthDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
      width: '100%',
      backgroundImage: "none"
    },
}));


interface Props {
    open: boolean
    anchor: 'left' | 'right' | 'top' | 'bottom'
    onClose: Function
}
const ResponsiveDrawer = (props: Props & PropsWithChildren)=>{
    const {isLg} = useBreakpoints()

    return isLg ? (
        <Drawer open={props.open} anchor={props.anchor}>
            {props.children}
        </Drawer>
    ):(
        <FullWidthDrawer open={props.open} anchor={props.anchor}>
            {props.children}
        </FullWidthDrawer>
    )
}

export default ResponsiveDrawer