import { useQuery } from "@apollo/client";
import { Button, Menu, MenuItem } from "@mui/material";
import apiClient, { ApiClient } from "../../../../lib/apiClient";
import React from "react";
import useSession from "../../../../helpers/useSessionState";
import { useNavigate } from "react-router-dom";

const CompanyDropdown = ()=>{
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const [sessionState, setSession] = useSession()
    const { data } = useQuery<ApiClient.GetInitialData>(apiClient.queries.GET_CURRENT_USER_DATA);
    
    const handleSelect = (company: ApiClient.GetCompanies["companies"][0], e: any)=>{
        setSession({
            ...Object(sessionState),
            currentCompanyId: company.id
        })
        handleClose()
    }
        
    
    const company = data?.companies.find((company)=>
        company.id === sessionState?.currentCompanyId
    )

    const companies = data?.companies.filter((_company)=>
        _company.id == sessionState?.currentCompanyId
    )

    return <>
        <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
        >
            {company?.name}
        </Button>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
        >
            {companies?.map((_company)=>(
                <MenuItem 
                    onClick={handleSelect.bind(null, _company)}
                    key={_company.id}
                >
                    {_company.name}
                </MenuItem>
            ))}
        </Menu>
    </>
}

export default CompanyDropdown